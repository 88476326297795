(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/poker/common/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/poker/common/assets/javascripts/constants.js');

'use strict';

const gameTypes = {
  PS_TW_HE_SNG_0_EUR: 0,
  PS_TW_HE_SNG_0_5_EUR: 0.5,
  PS_TW_HE_SNG_1_EUR: 1,
  PS_TW_HE_SNG_2_EUR: 2,
  PS_TW_HE_SNG_2_5_EUR: 2.5,
  PS_TW_HE_SNG_5_EUR: 5,
  PS_TW_HE_SNG_10_EUR: 10,
  PS_TW_HE_SNG_20_EUR: 20,
  PS_TW_HE_SNG_50_EUR: 50,
  PS_TW_HE_SNG_100_EUR: 100,
  PS_WT_HE_SNG_0_5_EUR: 0.5,
  PS_WT_HE_SNG_1_EUR: 1,
  PS_WT_HE_SNG_2_EUR: 2,
  PS_WT_HE_SNG_3_EUR: 3,
  PS_WT_HE_SNG_7_EUR: 7,
  PS_AOG_HE_SNG_1_EUR: 1,
  PS_AOG_HE_SNG_5_EUR: 5,
  PS_AOG_HE_SNG_10_EUR: 10
};
function getProductName(gameType) {
  switch (gameType) {
    case 'PS_TW_HE_SNG_0_EUR':
    case 'PS_TW_HE_SNG_0_5_EUR':
    case 'PS_TW_HE_SNG_1_EUR':
    case 'PS_TW_HE_SNG_2_EUR':
    case 'PS_TW_HE_SNG_2_5_EUR':
    case 'PS_TW_HE_SNG_5_EUR':
    case 'PS_TW_HE_SNG_10_EUR':
    case 'PS_TW_HE_SNG_20_EUR':
    case 'PS_TW_HE_SNG_50_EUR':
    case 'PS_TW_HE_SNG_100_EUR':
      return 'twister';
    case 'PS_WT_HE_SNG_0_5_EUR':
    case 'PS_WT_HE_SNG_1_EUR':
    case 'PS_WT_HE_SNG_2_EUR':
    case 'PS_WT_HE_SNG_3_EUR':
    case 'PS_WT_HE_SNG_7_EUR':
      return 'wild_twister';
    case 'PS_AOG_HE_SNG_1_EUR':
    case 'PS_AOG_HE_SNG_5_EUR':
    case 'PS_AOG_HE_SNG_10_EUR':
    default:
      return 'age_of_god_twister';
  }
}
const BrowserStorageConstants = {
  OpenWebClientTableKey: 'openWebClientTable',
  Domain: 'poker'
};
const trackingEvents = {
  PROMO_CLICK: 'promoClick',
  TRACK_EVENT: 'trackEvent'
};
const exported = {
  gameTypes,
  BrowserStorageConstants,
  trackingEvents,
  getProductName
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.components.poker.common.constants = exported;
}

 })(window);