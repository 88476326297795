(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/poker/common/assets/javascripts/util.js') >= 0) return;  svs.modules.push('/components/poker/common/assets/javascripts/util.js');

'use strict';

const {
  BrowserStorageConstants,
  trackingEvents
} = svs.components.poker.common.constants;
const {
  constants: {
    EXPIRES_10M
  },
  browser: BrowserStorage
} = svs.components.Storage;
const trackPokerTwisterEvent = trackingObject => {
  if (trackingObject.trackingType === trackingEvents.TRACK_EVENT) {
    svs.components.analytics.trackEvent({
      category: 'Poker',
      action: 'poker_widget',
      opt_label: trackingObject.opt_label
    });
  } else if (trackingObject.trackingType === trackingEvents.PROMO_CLICK && trackingObject.trackingString) {
    svs.components.analytics.trackPromoClick({
      name: trackingObject.trackingString,
      position: parseInt(trackingObject.position, 10)
    });
  }
};
const setBrowserStorage = gameType => {
  BrowserStorage.set(BrowserStorageConstants.OpenWebClientTableKey, BrowserStorageConstants.Domain, {
    openWebClientTable: gameType
  }, EXPIRES_10M, () => {
    svs.components.customer_login.login();
  });
};
const startPokerTwister = gameType => {
  const pokerHome = svs.core.urlMapping.get('pokerHome');
  const isMobile = svs.core.detect.formfactor.mobile();
  const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  if (!isLoggedIn) {
    setBrowserStorage(gameType);
  } else {
    const height = window.innerHeight / 2;
    const width = window.innerWidth / 2;
    const setDimensions = isMobile ? 'popup' : "width=".concat(width, ", height=").concat(height);
    window.open("".concat(pokerHome, "/spela/start-table?formFactor=").concat(isMobile ? 'mobile' : 'desktop', "&tableType=").concat(gameType), '', setDimensions);
  }
};
const launchTwisterFromStorage = () => {
  BrowserStorage.get(BrowserStorageConstants.OpenWebClientTableKey, BrowserStorageConstants.Domain, data => {
    if (data !== null && data !== void 0 && data.openWebClientTable) {
      startPokerTwister(data.openWebClientTable);
      BrowserStorage.remove(BrowserStorageConstants.OpenWebClientTableKey, BrowserStorageConstants.Domain);
    }
  });
};
const exported = {
  startPokerTwister,
  trackPokerTwisterEvent,
  launchTwisterFromStorage
};
svs.components.poker.common.util = exported;

 })(window);